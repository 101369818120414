<template>
  <div class="flex flex-wrap items-end p-6 lg:p-8 bg-gradient-black-01 rounded-xl ring-1 ring-gray-200/10">
    <h2 class="mr-4 text-2xl leading-tight text-white lg:text-3xl">
      {{ title }}
    </h2>
    <button class="relative order-3 mt-8 ml-auto text-sm pb-0.5 text-white bottom-line-inverted md:order-2 md:mt-0 duration-500"
            :class="!area && !location && !employmentType && !workTypes?.length ? 'opacity-0 pointer-events-none' : ''"
            @click="clearFilter"
    >
      Clear filters
    </button>
    <div class="grid w-full mt-8 md:grid-cols-2 lg:grid-cols-4 lg:mt-9 gap-y-6 gap-x-8 md:order-3">
      <Dropdown :items="AREAS"
                :model-value="area"
                :placeholder="AreaLabel.ALL"
                class="z-[12]"
                @update:model-value="item => setSelected(item, JobFilterId.AREA)"
      />

      <Dropdown :items="LOCATIONS"
                :model-value="location"
                :placeholder="LocationLabel.ALL"
                class="z-[11]"
                @update:model-value="item => setSelected(item, JobFilterId.LOCATION)"
      />

      <Dropdown :items="EMPLOYMENT_TYPES"
                :model-value="employmentType"
                :placeholder="EmploymentTypeLabel.ALL"
                class="z-10"
                @update:model-value="item => setSelected(item, JobFilterId.EMPLOYMENT_TYPE)"
      />
      <Dropdown :items="WORK_TYPES"
                :model-value="workTypes"
                :placeholder="WorkTypeLabel.ALL"
                multiple
                class="z-9"
                @update:model-value="item => setSelected(item, JobFilterId.WORK_TYPE)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ComboboxItem } from '../../../models/combobox'
import { EMPLOYMENT_TYPES, AREAS, JobFilterId, LOCATIONS, WORK_TYPES, AreaLabel, LocationLabel, EmploymentTypeLabel, WorkTypeLabel } from '~/constants/jobs'

interface filterProps {
  title: string
  area: ComboboxItem
  location: ComboboxItem
  employmentType: ComboboxItem
  workTypes: Array<ComboboxItem>
}

const route = useRoute()
const router = useRouter()

defineProps<filterProps>()

defineExpose({
  clearFilter
})

function clearFilter() {
  router.push({
    query: {
      ...route.query,
      area: undefined,
      location: undefined,
      employmentType: undefined,
      workType: []
    }
  })
}

function setSelected(item: ComboboxItem | Array<ComboboxItem>, id: JobFilterId) {
  const newRouteLocation = {
    query: { ...route.query }
  }

  if (!item) {
    newRouteLocation.query[id] = undefined
  }
  else if (Array.isArray(item)) {
    newRouteLocation.query[id] = item.map(item => item.key).join(',')
  }
  else {
    newRouteLocation.query[id] = item.key
  }

  router.push(newRouteLocation)
}
</script>

<style>
.bottom-line-inverted:before,
.bottom-line-inverted:after {
  @apply absolute bottom-0 w-full  h-px  bg-white;
  content: '';
}

.bottom-line-inverted:before {
  @apply left-0 bg-white;
  transition: width 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.bottom-line-inverted:after {
  @apply w-full bg-transparent right-0;
  transition: 0s;
}

.bottom-line-inverted:hover:before {
  @apply w-0 bg-transparent;
}

.bottom-line-inverted:hover:after {
  @apply w-0 bg-white;
  transition: width 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
}
</style>
